





















































































































































































































































































































































































































































































































.contentUs {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .textarea {
        width: 100%;

        ::v-deep .ivu-input {
            height: 1rem !important;

            @media (max-width: 767px) {
                height: 0.5rem * 1.5 !important;
            }
        }
    }
}
